<template lang="pug">
head-meta(:title-page="titlePage", :description="descriptionPage")
//crumbs(:list="crumbs")
spinner(v-if="isLoading")
template(v-else)
  template(v-if="!isNotFound")
    category-cap(category-name="Товары в наличии", :current-page="currentPage")
    informer-line(v-if="banners.length", :banners="banners", mod="category-action")
    filters-line(
      v-if="filters.length"
      :filters-start="filters"
      :sort="sort",
      :products="products",
      :color-count="colorCount",
      :product-count="productCount",
      @sort-products="sortProducts",
      @apply-filters="applyFilters"
    )
    tile-line(
      :current-page="currentPage",
      :pages="pages",
      :products="products",
      :is-loading-button="isLoadingButton"
      @show-more="showMore",
      @update-page="updatePage"
    )
  thanks404(v-else)
</template>

<script>
import Crumbs from '../components/Crumbs'
import CategoryCap from '../components/category/CategoryCap'
import { getProductsInStock, getBanners } from '../assets/scripts/Api'
import InformerLine from '../components/InformerLine'
import HeadMeta from '../components/_layout/HeadMeta'
import FiltersLine from '../components/FiltersLine'
import TileLine from '../components/_tiles/TileLine'
import Spinner from '../components/Spinner'
import Thanks404 from '../components/Thanks404'

export default {
  name: 'ProductsInStockPage',
  components: { Thanks404, TileLine, FiltersLine, HeadMeta, InformerLine, Crumbs, CategoryCap, Spinner },
  data () {
    return {
      products: [],
      category: {
        name: 'Акции и скидки'
      },
      crumbs: [{ route: { name: 'ProductsInStock' }, name: 'Товары в наличии' }],
      filters: [],
      currentPage: 1,
      pages: 1,
      selectedFilters: {},
      sort: '',
      banners: [],
      isLoading: false,
      isNotFound: false,
      isLoadingButton: false,
      startProducts: [],
      startPage: 1,
      productCount: 0
    }
  },
  computed: {
    titlePage () {
      if (this.isNotFound) return 'Страница не найдена'
      return this.currentPage > 1
        ? `Купить товары по акции в Москве и Московской области - страница ${this.currentPage}`
        : 'Купить товары по акции в Москве и Московской области'
    },
    descriptionPage () {
      if (this.isNotFound) return 'Проверьте ссылку на страницу, возможно она была удалена или перемещена'
      return this.currentPage > 1
        ? `Акции и скидки в магазинах сети CHIEDOHOME. Доставка по Москве и Московской области - страница ${this.currentPage}`
        : 'Акции и скидки в магазинах сети CHIEDOHOME. Доставка по Москве и Московской области'
    },
    colorCount () {
      if (this.filters) {
        const colorFilters = this.filters.find(item => item.slug === 'tsvet')
        if (!colorFilters) return 0
        return colorFilters.list.filter(item => item.is_current).length
      }
      return 0
    }
  },
  methods: {
    async getDataPage (page = 1) {
      this.isLoading = true
      const data = await getProductsInStock(page, this.sort, this.selectedFilters)
      if (!data || data.status === 'error') this.isNotFound = true
      this.isLoading = false
      this.placeProductsData(data)
    },
    placeBanners (banners) {
      this.banners = banners.small.splice(0, 2)
    },
    applyFilters (filters) {
      this.currentPage = 1
      this.selectedFilters = filters
      this.startProducts = []
      this.getDataPage()
    },
    sortProducts (sort) {
      this.sort = sort
      this.refreshProducts()
    },
    refreshProducts () {
      if (this.currentPage === 1) {
        this.$router.push({ name: this.$route.name, params: { page: '', numberPage: '' }, query: { sort: this.sort } })
      } else {
        this.$router.push({ name: this.$route.name, params: { page: 'page', numberPage: this.currentPage }, query: { sort: this.sort } })
      }
    },
    isNeedErrorRedirect (to) {
      const page = to.params.page
      const number = to.params.numberPage
      if ((page && !number) || (!page && number)) {
        this.isNotFound = true
        return true
      }
      return false
    },
    placeProductsData (data) {
      if (data.status === 'error') this.isNotFound = true
      this.products = data.products
      this.filters = data.filters
      this.currentPage = data.page || 1
      this.pages = data.page_count
      this.productCount = data.products_count
      if (!this.startProducts.length) {
        this.startProducts = this.products
        this.startPage = this.currentPage
      }
    },
    getParseProducts () {
      const app = document.querySelector('#app')
      const products = app.dataset.productsInStock
      return products ? JSON.parse(products) : null
    },
    checkPreloadProducts (route) {
      const parseProducts = this.getParseProducts()
      const pageFromRoute = +route.params.numberPage || 1
      const sort = route.query.sort || ''
      const isEmptyFilters = !Object.keys(this.selectedFilters).length
      return parseProducts && parseProducts.page === pageFromRoute && parseProducts.sort === sort && isEmptyFilters
    },
    setProducts (route, page) {
      if (this.checkPreloadProducts(route)) {
        const parseProducts = this.getParseProducts()
        this.placeProductsData(parseProducts)
      } else {
        this.getDataPage(page)
      }
    },
    async showMore (page) {
      this.isLoadingButton = true
      const data = await getProductsInStock(page, this.sort, this.selectedFilters)
      this.isLoadingButton = false
      this.currentPage = page
      this.products = [...this.products, ...data.products]
      const url = this.sort ? `/products-in-stock/page/${page}?sort=${this.sort}` : `/products-in-stock/page/${page}`
      history.replaceState(history.state, '', url)
    },
    updatePage (path) {
      if (path === this.$route.fullPath) {
        history.replaceState(history.state, '', path)
        this.products = this.startProducts
        this.currentPage = this.startPage
      } else {
        this.startProducts = []
        this.startPage = 1
      }
    }
  },
  mounted () {
    if (this.isNeedErrorRedirect(this.$route)) return
    if (this.$route.params.numberPage) this.currentPage = +this.$route.params.numberPage
    if (this.$route.query.sort) this.sort = this.$route.query.sort
    this.setProducts(this.$route, this.currentPage)
    const banners = document.querySelector('#app').dataset.banners
    banners ? this.placeBanners(JSON.parse(banners)) : getBanners().then(data => this.placeBanners(data))
  },
  beforeRouteUpdate (to, from, next) {
    this.isNotFound = false
    if (this.isNeedErrorRedirect(to)) return
    if (to.query.sort !== from.query.sort) this.startProducts = []
    to.params.numberPage ? this.setProducts(to, +to.params.numberPage) : this.setProducts(to)
    next()
  }
}
</script>
